import React from 'react'
import './SeasonalityBar.css'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

// Creates a map like so: {January: 0, February: 0, March: 0, April: 0, May: 0, …}
const newMonthMap = () => {
  return months.reduce((monthMap, m) => {
    monthMap[m] = 0
    return monthMap
  }, {})
}

const getMonthMap = monthsInSeason => {
  const monthMap = newMonthMap()
  if (Number.isInteger(monthsInSeason[0])) {
    monthsInSeason.map(i => monthMap[months[i]] = 1)
    return monthMap
  }
  monthsInSeason.map(m => monthMap[m] = 1)
  return monthMap
}

const SeasonalityBar = (props) => {
  const monthMap = getMonthMap(props.months)
  return (
    <div className='seasonality-bar'>
      {months.map(m => {
        const inSeason = monthMap[m] !== 0
        return (
          <div
            className={inSeason ? 'in-season' : ''}
            key={m}
            >
            {m[0]}
          </div>
        )
      })}
    </div>
  )
}

export default SeasonalityBar
