export const transformFoodItem = data => {
  const {
    Name,
    Category,
    Image,
    About,
    Use,
    Storage,
    Harvest_Months,
    Producers,
    Retailers,
    Citation,
    Recipe_Ingredient_Amount,
  } = data

  const image = Image ? Image[0].url : ''

  const producers = Producers
    ? Producers.map(p => {
        return {
          image: p.data.Logo ? p.data.Logo[0].url : '',
          title: p.data.Name,
          description: '',
          url: p.data.Website,
        }
      })
    : []

  const retailers = Retailers
    ? Retailers.map(r => {
        return {
          image: r.data.Image ? r.data.Image[0].url : '',
          title: r.data.Name,
          description: '',
          url: r.data.Website,
        }
      })
    : []

  const recipes = Recipe_Ingredient_Amount
    ? Recipe_Ingredient_Amount.map(r => {
        return {
          title: r.data.Recipe[0].data.Title,
          image: r.data.Recipe[0].data.Image[0].url,
          url: r.data.Recipe[0].data.Link,
        }
      })
    : []

  let infoSources = About ? About.split(/\\\\source\\\\/) : []
  const description = About ? infoSources[0] : null
  infoSources = infoSources.slice(1)

  const item = {
    title: Name,
    type: Category.toLowerCase(),
    seasons: Harvest_Months ? Harvest_Months : [],
    preparation: Use,
    storage: Storage,
    citation: Citation,
    description,
    infoSources,
    image,
    producers,
    retailers,
    recipes,
  }
  return item
}
