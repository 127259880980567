import React from 'react'
import { Input, Dropdown } from 'semantic-ui-react'
import './SearchFilterInputBar.less'

const SearchFilterInputBar = (props) => {
  const {
    search,
    handleSearchChange,
    filter,
    handleFilterChange,
    filterOptions
  } = props
  return (
    <div className='search-filter-input-container'>
      <Input
        icon='search'
        placeholder='Search...'
        iconPosition='left'
        value={search}
        onChange={handleSearchChange}
      />
      <Dropdown
        icon='filter'
        onChange={handleFilterChange}
        value={filter}
        options={filterOptions}
      />
    </div>
  )
}

export default SearchFilterInputBar
