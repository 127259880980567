import React from 'react'
import './ContactCard.css'

const getImageStyle = (url) => {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: `cover`,
  }
}

const openTab = (url) => {
  window.open(url, '_blank');
}

const ContactCard = (props) => {
  const { image, title, description, url } = props
  return (
    <div className='contact-card' onClick={() => openTab(url)}>
      <div className='image-container'>
        <div className='image' style={getImageStyle(image)}/>
      </div>
      <div className='info-container'>
        <div className='title'>{title}</div>
        <div className='description'>{description}</div>
      </div>
    </div>
  )
}

export default ContactCard
