import React from 'react'
import { Card } from 'semantic-ui-react'

const getImageStyle = (url) => {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: `cover`
  }
}

const getAmount = (amount) => {
  return amount
    ? <span>{amount}<br/></span>
    : ''
}

const renderHeaders = (shape, title, amount) => {
  return shape !== 'circular'
    ? (
      <>
        <Card.Header as='h5' className='san-serif header-top bold'>
          { getAmount(amount) }
        </Card.Header>
        <Card.Header as='h5' className='san-serif header-bottom'>
          {title}
        </Card.Header>
      </>
    ) : (
      <Card.Header as='h5' className='san-serif header-bottom'>
        { getAmount(amount) }
        {title}
      </Card.Header>
    )
}

const ItemCard = (props) => {
  const {
    title,
    image,
    onClick,
    ingredientAmount,
    shape
  } = props
  const defaultClasses = 'item-card image-card'
  const classes = shape ? `${defaultClasses} ${shape}` : defaultClasses
  return (
    <Card className={classes} onClick={onClick}>
      <Card.Content
        className="background-image"
        style={getImageStyle(image)} />
      { renderHeaders(shape, title, ingredientAmount) }
    </Card>
  )
}

export default ItemCard;
