import React from 'react'
import { Header, Button, Grid, Card } from 'semantic-ui-react'
import RecipeCard from './RecipeCard'
import ContactCard from './ContactCard'
import SeasonalityBar from './SeasonalityBar'
import './FoodItemDetail.less'

const renderBusinessInfo = bInfo => {
  return (
    <ContactCard
      key={bInfo.title}
      image={bInfo.image}
      title={bInfo.title}
      url={bInfo.url}
      description={bInfo.description}
    />
  )
}

const FoodItemDetail = props => {
  const { handleClose } = props
  console.log(props.item)
  const {
    title,
    description,
    preparation,
    seasons,
    producers,
    retailers,
    storage,
    citation,
    infoSources,
    recipes,
  } = props.item // TODO: retrieve this data

  return (
    <div className="food-item-detail">
      <div className="content-container">
        {seasons && seasons.length > 0 && <SeasonalityBar months={seasons} />}
        <Header as="h1" className="bold">
          {title}
        </Header>
        <div className="description">
          {description}
          <Header sub className="san-serif light">
            {citation}
          </Header>
        </div>
        {infoSources && infoSources.length > 0 && (
          <p>
            sources:
            {infoSources.map((s, i) => (
              <span>
                {' '}
                <a href="s">{i + 1}</a>{' '}
              </span>
            ))}
          </p>
        )}

        {/* TODO <Grid className='producer-retailer section-container' columns={2} divided>
            <Grid.Row>
              <Grid.Column className='producer-list'>
                <Header as='h5' className='san-serif light'>Producers to look for.</Header>
                <div className='contacts-container'>
                  {producers.map(p => renderBusinessInfo(p))}
                </div>
              </Grid.Column>
              <Grid.Column className='retailer-list'>
                <Header as='h5' className='san-serif light'>Where to buy.</Header>
                <div className='contacts-container'>
                  {retailers.map(r => renderBusinessInfo(r))}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid> */}
        {preparation && (
          <div className="preparation section-container">
            <Header as="h5" className="san-serif light">
              How to prepare...
            </Header>
            <div className="text-container">{preparation}</div>
          </div>
        )}
        {storage && (
          <div className="storage section-container">
            <Header as="h5" className="san-serif light">
              How to store...
            </Header>
            <div className="text-container">{storage}</div>
          </div>
        )}
        {recipes &&
          recipes.length > 0 && (
            <div className="recipes-container section-container">
              <Header as="h5" className="san-serif light">
                Sample Seasonal Recipes
              </Header>
              <Card.Group className="recipe-list text-container">
                {recipes.map(r => {
                  console.log(r)
                  return (
                    <RecipeCard
                      key={r.title}
                      title={r.title}
                      image={r.image}
                      url={r.url} />
                  )
                })}
              </Card.Group>
            </div>
          )}
      </div>
      <Button className="close-button" icon="close" onClick={handleClose} />
    </div>
  )
}

export const itemDetailFragment = graphql`
  fragment ItemDetailFragment on AirtableLinked {
    data {
      Name
      Image {
        url
      }
      Harvest_Months
      Category
      About
      Use
      Publish
      Storage
      Type
      Citation
      Producers {
        data {
          Name
          Logo {
            url
          }
          Website
        }
      }
      Retailers {
        data {
          Name
          Image {
            url
          }
          Website
        }
      }
      Recipe_Ingredient_Amount {
        data {
          Recipe {
            data {
              Title
              Image {
                url
              }
              Link
            }
          }
        }
      }
    }
  }
`

export default FoodItemDetail
