import React, { Component } from 'react'
import { Card, Container, Header } from 'semantic-ui-react'
import RecipeCard from './RecipeCard'
import FoodItemCard from './FoodItemCard'
import FoodItemDetail from './FoodItemDetail'
import styles from './ItemGrid.less'
import './index.less'

class ItemGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemSelected: null,
      rowIndexExpanded: null,
      columns: 2,
    }
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  getColumns = width => {
    // Sets breakpoints such that the grid makes up at maximum, 80% of
    // window width, where the grid size is determined by...
    // = (--card-width + --card-spacing [0.5 * @emSize]) * number-columns
    const brakePoints = [
      0,
      0,
      parseInt(styles.threeColBreakPoint),
      parseInt(styles.fourColBreakPoint),
      parseInt(styles.fiveColBreakPoint),
    ]
    return brakePoints.reduceRight((numCols, bp, i) => {
      return bp <= width ? numCols : i
    }, brakePoints.length)
  }

  onResize = () => {
    // const width = this.gridElRef.current.offsetWidth;
    const width = window.innerWidth
    const columns = this.getColumns(width)
    if (columns !== this.state.columns) {
      this.setState({ columns })
    }
  }

  expandItem = (item, row) => {
    this.setState({
      rowIndexExpanded: row,
      itemSelected: item,
    })
  }

  closeExpandedItem = () => {
    this.setState({
      rowIndexExpanded: null,
      itemSelected: null,
    })
  }

  renderZeroState = () => {
    return (
      <Container className="zero-state">
        <Header as="h5">We couldn't find that one...</Header>
        <p>Try changing your search, or updating the season.</p>
      </Container>
    )
  }

  renderItem = (item, row) => {
    const { type, title, image, url, amount, measure } = item
    return type === 'recipe' ? (
      <RecipeCard title={title} image={image} key={title} url={url} />
    ) : (
      <FoodItemCard
        key={title}
        shape={type === 'pantry' ? 'circular' : ''}
        title={title}
        image={image}
        ingredientAmount={amount && `${amount} ${measure}`}
        onClick={e => {
          this.expandItem(item, row)
        }}
      />
    )
  }

  renderRow = (row, index) => {
    return (
      <React.Fragment key={index}>
        <div className="grid-row" key={index}>
          <Card.Group stackable={false}>
            {row.map(item => this.renderItem(item, index))}
          </Card.Group>
          {/* { row.map(item => this.renderItem(item, index))} */}
        </div>
        {this.state.itemSelected &&
          this.state.rowIndexExpanded === index && (
            <div className="grid-row" key={`detail-${index}`}>
              <FoodItemDetail
                title={this.state.itemSelected.title}
                item={this.state.itemSelected}
                handleClose={this.closeExpandedItem}
              />
            </div>
          )}
      </React.Fragment>
    )
  }

  createRows = items => {
    let currentRow = []
    let currentRowCapacity = this.state.columns
    const rows = []
    for (let i = 0; i <= items.length; i++) {
      if (currentRowCapacity === 0) {
        rows.push(currentRow)
        currentRow = []
        currentRowCapacity = this.state.columns
      }
      currentRow.push(items[i])
      currentRowCapacity--
    }
    if (currentRow.length !== 0) rows.push(currentRow)
    return rows
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Based on the the number of columns, this creates a grid of items
  // and recipes, interspersing the recipes after every few items
  // TODO: CLEAN UP, THIS IS MESSY
  createRowsWithRecipes = (items, recipes) => {
    const numRecipes = recipes ? recipes.length : 0
    let recipesCopy = recipes ? [...recipes] : []
    let currentRow = recipesCopy.length > 0 ? [recipesCopy.pop()] : [] // Always start with a recipe
    let currentRowCapacity =
      recipesCopy.length > 0 ? this.state.columns - 2 : this.state.columns
    let countSinceLastRecipe = 0
    let itemIndex = 0
    let numBtwnRecipes = this.state.columns + 1
    const rows = []
    for (let i = 1; i <= items.length + numRecipes; i++) {
      // console.log("i: ", i, "currentRowCapacity: ", currentRowCapacity, ", currentRow: ", currentRow, ", countSinceLastRecipe: ", countSinceLastRecipe)
      // If the current row is full, create a new row
      if (
        currentRowCapacity === 0 ||
        // OR only recipes are left
        (currentRowCapacity < 2 && items.length === itemIndex)
      ) {
        rows.push(currentRow)
        currentRow = []
        currentRowCapacity = this.state.columns
      }

      // Add in a recipe every X items in the grid, where X is the number
      // of columns
      if (
        (countSinceLastRecipe >= numBtwnRecipes ||
          itemIndex === items.length) &&
        recipesCopy.length > 0 &&
        currentRowCapacity >= 2
      ) {
        currentRow.push(recipesCopy.pop())
        currentRowCapacity -= 2
        countSinceLastRecipe = 0
        numBtwnRecipes = numBtwnRecipes === this.state.columns
          ? this.state.columns + 1
          : this.state.columns
      } else if (itemIndex < items.length) {
        currentRow.push(items[itemIndex])
        currentRowCapacity--
        countSinceLastRecipe++
        itemIndex++
      }
    }
    if (currentRow.length !== 0) rows.push(currentRow)
    return rows
  }

  render() {
    const { items, recipes } = this.props
    // const rows = recipes
    //   ? this.createRowsWithRecipes(items, recipes)
    //   : this.createRows(items)

    const rows = this.createRowsWithRecipes(items, recipes)

    return (
      <div className="grid-container">
        {rows.length > 0
          ? rows.map((row, i) => this.renderRow(row, i))
          : this.renderZeroState()}
      </div>
    )
  }
}

export default ItemGrid
