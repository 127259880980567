import React from 'react'
import { format } from 'date-fns'
import { Header, Dropdown } from 'semantic-ui-react'
import './Hero.less'

import EmailForm from './EmailForm'

const today = new Date()

const months = [
  {
    value: 'The Year',
    text: 'The Year',
    key: ''
  },
  {
    value: 'January',
    text: 'January',
    key: 'January'
  },
  {
    value: 'February',
    text: 'February',
    key: 'February'
  },
  {
    value: 'march',
    text: 'March',
    key: 'March'
  },
  {
    value: 'April',
    text: 'April',
    key: 'April'
  },
  {
    value: 'May',
    text: 'May',
    key: 'May'
  },
  {
    value: 'June',
    text: 'June',
    key: 'June'
  },
  {
    value: 'July',
    text: 'July',
    key: 'July'
  },
  {
    value: 'August',
    text: 'August',
    key: 'August'
  },
  {
    value: 'September',
    text: 'September',
    key: 'September'
  },
  {
    value: 'October',
    text: 'October',
    key: 'October'
  },
  {
    value: 'November',
    text: 'November',
    key: 'November'
  },
  {
    value: 'December',
    text: 'December',
    key: 'December'
  },
]

const places = [
  {
    value: 'Stockholm',
    text: 'Stockholm',
    key: 'Stockholm'
  }
]

const Hero = (props) => {
  const {
    month,
    geo,
    handleMonthChange,
    handleGeoChange
  } = props

  return (
    <div className='hero-container'>
      <div className='hero-content-container'>
        <Header sub className='san-serif white'>{format(today, 'dddd, MMMM Do')}</Header>
        <Header as='h4' className='white'>Explore seasonal recipes and nourishing food produced near you.</Header>
        <Dropdown
          search
          selection
          options={months}
          onChange={handleMonthChange}
          defaultValue={month}
        />
        <Header sub className='month-geo-divider san-serif white'>in</Header>
        <Dropdown
          search
          selection
          options={places}
          onChange={handleGeoChange}
          defaultValue={geo}
        />
      </div>
      <div className='email-form-container'>
        <EmailForm />
        <p>
          Stay up to date with the changing harvest!
        </p>
      </div>
    </div>
  )
}

export default Hero
