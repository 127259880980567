import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { format } from 'date-fns'
import slugify from 'slugify'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ItemGrid from '../components/ItemGrid'
import SearchFilterInputBar from '../components/SearchFilterInputBar'
import { transformFoodItem } from '../lib/dataTransform.js'

class IndexPage extends Component {
  constructor(props) {
    super(props)
    const today = new Date()
    const currentMonth = format(today, 'MMMM').toString()
    this.state = {
      filter: '',
      search: '',
      month: currentMonth,
      geo: 'Stockholm',
    }
  }

  onChangedHandler = key => (e, { value }) => {
    const state = {}
    state[key] = value
    this.setState(state)
  }

  isItemType = item => {
    const { filter } = this.state
    if ( item.type !== 'vegetable'
      && item.type !== 'fruit'
      && item.type !== 'recipe'
    ) return false
    if (filter === '') return true
    if (filter === 'recipe') return item.type === 'recipe'

    return filter === 'recipe'
      ? item.type === 'recipe'
      : item.type !== 'recipe'
  }

  isInSeason = item => {
    return item.seasons && this.state.month !== 'The Year'
      ? item.seasons.includes(this.state.month)
      : true
  }

  isInGeo = item => {
    return item.locations ? item.locations.includes(this.state.geo) : true
  }

  getVisibleItems = items => {
    const { search } = this.state
    const searchValue = search.toLowerCase()
    const filteredItems = items.filter(item => {
      const itemValue = item.title.toLowerCase()
      return (
        this.isItemType(item) &&
        this.isInSeason(item) &&
        this.isInGeo(item) &&
        itemValue.indexOf(searchValue) >= 0
      )
    })
    return filteredItems.sort((a, b) => {
      if (a.title < b.title) return -1
      return a.title > b.title ? 1 : 0
    })
  }

  transformItems = items => {
    return items.map(({ node: { data }}) => {
      return transformFoodItem(data)
    })
  }

  transformRecipes = recipes => {
    return recipes.map(({ node: { data } }) => {
      const image = data.Image ? data.Image[0].url : ''
      return {
        title: data.Title,
        type: 'recipe',
        url: data.Link,
        image,
      }
    })
  }

  render() {
    const { month, geo } = this.state
    const foodItems = this.transformItems(this.props.data.foodItems.edges)
    const visibleItems = this.getVisibleItems(foodItems)
    const recipes = this.transformRecipes(this.props.data.recipes.edges)
    const visibleRecipes = this.getVisibleItems(recipes)

    return (
      <Layout>
        <Hero
          month={month}
          geo={geo}
          handleMonthChange={this.onChangedHandler('month')}
          handleGeoChange={this.onChangedHandler('geo')}
        />
        <SearchFilterInputBar
          search={this.state.search}
          filter={this.state.filter}
          handleSearchChange={this.onChangedHandler('search')}
          handleFilterChange={this.onChangedHandler('filter')}
          filterOptions={[
            { text: 'All', value: '' },
            { text: 'recipe', value: 'recipe' },
            { text: 'produce', value: 'produce' },
          ]}
        />
        <ItemGrid items={visibleItems} recipes={visibleRecipes} />
      </Layout>
    )
  }
}

export const query = graphql`
  query FoodItemQuery {
    foodItems: allAirtableLinked(filter: { table: { eq: "Food Items" }, data: { Publish: {eq: true}} }) {
      edges {
        node {
          ...ItemDetailFragment
        }
      }
    }
    recipes: allAirtableLinked(filter: { table: { eq: "Recipes" }, data: { Publish: {eq: true}} }) {
      edges {
        node {
          id
          data {
            Title
            Image {
              url
            }
            Link
          }
        }
      }
    }
  }
`

export default IndexPage
