import React from 'react'
import { Card, Button, Header } from 'semantic-ui-react'
import { Link } from 'gatsby'
import slugify from 'slugify'

const getImageStyle = url => {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: `cover`,
    backgroundPositionY: '50%',
  }
}

const renderCardContent = (title, image) => {
  return (
    <>
    <Card.Content className="background-image" style={getImageStyle(image)} />
    <div className="header-top">
      <Header className="white san-serif" sub>
        RECIPE
      </Header>
      <Card.Header className="white" as="h2">
        {title}
      </Card.Header>
    </div>
    <Button className="normal white">
      Try it!
    </Button>
    </>
  )
}

const RecipeCard = props => {
  const { title, image, url } = props
  const linkUrl = url
    ? url
    : `/recipes/${slugify(title, { lower: true, })}`
  // TODO: this is pretty hacky
  return url
    ? (
      <Card
        onClick={() => window.open(url, '_blank')}
        className="recipe-card image-card double">
          {renderCardContent(title, image)}
      </Card>
    ) : (
      <Card
        as={Link}
        to={linkUrl}
        className="recipe-card image-card double">
          {renderCardContent(title, image)}
      </Card>
    )
}

export default RecipeCard
